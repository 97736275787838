<template>
  <v-stepper v-model="currentStep" elevation="0" class="transparent py-0 my-0">
    <v-stepper-header class="transparent elevation-0">
      <v-stepper-step
        step="1"
        class="transparent elevation-0 py-0"
        :complete="currentStep > 1"
        :color="currentStep > 1 ? 'success' : 'primary'"
      >
        {{ $t("excavatingLocation") }}
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step
        step="2"
        class="transparent elevation-0 py-0"
        :complete="currentStep > 2"
        :color="currentStep > 2 ? 'success' : 'primary'"
      >
        {{ $t("excavationDetails") }}
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step
        step="3"
        class="transparent elevation-0 py-0"
        :complete="currentStep > 3"
        :color="currentStep > 3 ? 'success' : 'primary'"
      >
        {{ $t("confirmAndSubmit") }}
      </v-stepper-step>
    </v-stepper-header>
  </v-stepper>
</template>

<script>
export default {
  name: "TicketProgressbar",
  props: {
    currentStep: {
      type: Number,
      default: () => 1,
    },
  },
};
</script>

<style lang="scss" scoped></style>
