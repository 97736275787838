var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-stepper",
    {
      staticClass: "transparent py-0 my-0",
      attrs: { elevation: "0" },
      model: {
        value: _vm.currentStep,
        callback: function ($$v) {
          _vm.currentStep = $$v
        },
        expression: "currentStep",
      },
    },
    [
      _c(
        "v-stepper-header",
        { staticClass: "transparent elevation-0" },
        [
          _c(
            "v-stepper-step",
            {
              staticClass: "transparent elevation-0 py-0",
              attrs: {
                step: "1",
                complete: _vm.currentStep > 1,
                color: _vm.currentStep > 1 ? "success" : "primary",
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("excavatingLocation")) + " ")]
          ),
          _c("v-divider"),
          _c(
            "v-stepper-step",
            {
              staticClass: "transparent elevation-0 py-0",
              attrs: {
                step: "2",
                complete: _vm.currentStep > 2,
                color: _vm.currentStep > 2 ? "success" : "primary",
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("excavationDetails")) + " ")]
          ),
          _c("v-divider"),
          _c(
            "v-stepper-step",
            {
              staticClass: "transparent elevation-0 py-0",
              attrs: {
                step: "3",
                complete: _vm.currentStep > 3,
                color: _vm.currentStep > 3 ? "success" : "primary",
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("confirmAndSubmit")) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }